import "./products.css"

const About = () => {

    return (
        <div id="products" className="products">
            <h2>Products</h2>
            <div className="pool">
                <div className="text">
                    <h4>Pool tables</h4>
                </div>
            </div>

            <div className="machines">
                <div className="juckbox prod">
                    <div className="text">
                        <h4>Jukeboxes</h4>
                    </div>
                </div>
                <div className="basket prod">
                    <div className="text">
                        <h4>Basketball games</h4>
                    </div>
                </div>
                <div className="atm prod">
                    <div className="text">
                        <h4>ATM</h4>
                    </div>
                </div>
            </div>
            
            <div className="machines">
                <div className="hockey prod">
                    <div className="text">
                        <h4>FX Air Hockey</h4>
                    </div>
                </div>
                <div className="car prod">
                    <div className="text">
                        <h4>car games</h4>
                    </div>
                </div>
                <div className="others prod">
                    <div className="text">
                        <h4>Boxer machines</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;