import React from "react";
import "./landing.css";
import { Helmet } from "react-helmet";

const Landing = () => {
    return (
        <div id="home" className="landing">
            <Helmet>
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "WILJO Promotional Video",
                        "description": "A promotional video showcasing the products and services offered by WILJO.",
                        "thumbnailUrl": "path/to/thumbnail.jpg",
                        "uploadDate": "2024-08-23T08:00:00+08:00",
                        "contentUrl": "path/to/video/main.mp4",
                        "embedUrl": "https://yourwebsite.com/path/to/video",
                        "publisher": {
                            "@type": "Organization",
                            "name": "WILJO",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "path/to/logo.jpg"
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>
            <video
                autoPlay
                muted
                loop
                playsInline
                aria-hidden="true"
                aria-label="Main promotional video for WILJO"
                title="Main promotional video for WILJO"
                poster={require('../../assets/images/landing/black.jpg')}
            >
                <source src={require('../../assets/videos/main.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="content">
                <h1 className="neonText">WILJO</h1>
            </div>
        </div>
    );
}

export default Landing;