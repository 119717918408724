import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./card.css"

const Card = ({ text, icon }) => {
    return (
        <div id="card" className="card">
            <FontAwesomeIcon className='icon' icon={icon} />
            <p>{text}</p>
        </div>
    );
}

export default Card;
