import React, { useState } from "react";
import { faGithub, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconsMapping from "../../assets/icons/iconsMapping";
import luis from "../../assets/images/team/luis.jpg";
import bill from "../../assets/images/team/will.jpg";
import jon from "../../assets/images/team/jon.png";
import "./teamCard.css";

const imageMap = {
    luis: luis,
    bill: bill,
    jon: jon
};

const TeamCard = ({ social, name, position, instagram, github, linkedin, bio }) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => {
        setModal(!modal);
    };

    const imageSrc = imageMap[name];

    if (modal) {
        document.body.classList.add('active-modal');
    } else {
        document.body.classList.remove('active-modal');
    }

    return (
        <>
            <div className="teamCard" onClick={toggleModal}>
                <div className="imgBx">
                    <img src={imageSrc} alt={name} />
                </div>

                <div className="content">
                    <div className="contentBx">
                        <h3>{name}<br /><span>{position}</span></h3>
                    </div>
                    {social && (
                        <ul className="sci">
                            <li style={{ '--i': 1 }}>
                                <a target='_blank' rel='noopener noreferrer' href={instagram} onClick={(e) => e.stopPropagation()}><FontAwesomeIcon className='icon' icon={faInstagram} /></a>
                            </li>
                            <li style={{ '--i': 2 }}>
                                <a target='_blank' rel='noopener noreferrer' href={github} onClick={(e) => e.stopPropagation()}><FontAwesomeIcon className='icon' icon={faGithub} /></a>
                            </li>
                            <li style={{ '--i': 3 }}>
                                <a target='_blank' rel='noopener noreferrer' href={linkedin} onClick={(e) => e.stopPropagation()}><FontAwesomeIcon className='icon' icon={faLinkedin} /></a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>

            {modal && (
                <div className="modal">
                    <div onClick={toggleModal} className="overlay"></div>
                    <div className="modal-content">
                        <h2>{name}</h2>
                        <p>{bio}</p>
                        <img
                            src={iconsMapping.close}
                            alt="Close"
                            className="close-modal"
                            onClick={toggleModal}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default TeamCard;