import Cue from "../../assets/icons/cue_white.svg"
import {Card} from "../../components"
import { faHandshake, faScrewdriverWrench, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import "./about.css"

const About = () => {

    return (
        <div id="about" className="about">
            <h2>About Us</h2>
            <p className="first"><span class="blue">WILJO</span> is a full service vending company providing <span class="red">Video Games</span>, <span class="green">Pool Tables</span>, <span class="yellow">Jukeboxes</span>, <span class="pink">ATM’s</span> and more machinery to the bars and restaurant community.</p>
            <img src={Cue} alt="" />
            <p className="second">We have been in existence for <span class="blue number">7</span> years and the owners have over <span class="blue number">30</span> years of experience in the industry.</p>
            <div className="cardContainer">
            <Card text="All the installation of equipment is done at no cost to you." icon={faDollarSign} />
            <Card text="Professional repair services available." icon={faScrewdriverWrench} />
            <Card text="We offer excellent customer service." icon={faHandshake} />
            </div>
            <p className="second">We understand the importance of maintaining and keeping equipment up and running, we make sure machine repair is executed in a timely fashion.</p>
        </div>
    );
}

export default About;