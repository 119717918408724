import { faEnvelope, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import axios from 'axios';
import "./contact.css";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('../../../handleForm.php', formData);
            if (response.data === 'The email message was sent.') {
                toast.success('Your message has been sent successfully!');
                // Clear the form fields
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                toast.error('Failed to send your message. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <div id="contact" className="contact">
            <h2>Contact Us</h2>
            <div className="contactForm">
                <form onSubmit={handleSubmit}>
                    <div className='cont_contact_header'>
                        <h1 className="sub-heading">Need Support?</h1>
                        <p className="para para2">Contact us for a quote.</p>
                    </div>
                    <input 
                        type="text" 
                        className="input" 
                        placeholder="Your name" 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required 
                    />
                    <input 
                        type="email" 
                        className="input" 
                        placeholder="Your email" 
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required 
                    />
                    <input 
                        type="text" 
                        className="input" 
                        placeholder="Your phone" 
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required 
                    />
                    <textarea 
                        className="input" 
                        cols="30" 
                        rows="5" 
                        placeholder="Your message..." 
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required 
                    ></textarea>
                    <input type="submit" className="input submit" value="Send Message" />
                </form>

                <div className="map-container">
                    <div className="mapBg"></div>
                    <div className="map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193596.00927566906!2d-74.14448686377237!3d40.69737092183334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNueva%20York%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1709534513769!5m2!1ses-419!2smx" 
                            width="600" 
                            height="450" 
                            style={{ border: 0 }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="New York City Map"
                        ></iframe>
                    </div>
                </div>
            </div>

            <div className="contactMethod">
                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faMapLocation} />
                    <article className="text">
                        <h1 className="sub-heading">Location</h1>
                        <p className="para">New York City, New York.</p>
                    </article>
                </div>

                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faEnvelope} />
                    <article className="text">
                        <h1 className="sub-heading">Email</h1>
                        <p className="para">wiljoone@yahoo.com</p>
                    </article>
                </div>

                <div className="method">
                    <FontAwesomeIcon className='icon contactIcon' icon={faPhone} />
                    <article className="text">
                        <h1 className="sub-heading">Phone</h1>
                        <p className="para">(929) 502-0337</p>
                    </article>
                </div>
            </div>

            <ToastContainer /> {/* Add ToastContainer to render toast notifications */}
        </div>
    );
}

export default Contact;